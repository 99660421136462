
  import { defineComponent, ref} from 'vue'
  export default defineComponent({
    setup(props) {
      const start = ref('')
      enum Color {dog = 1, pig='24', cat=23}
      let c:Color = Color.pig
      let d:string = Color[24]
      return {
        start,
        c,
        d
      }
    },
    // onMounted(() => {
      
    // })
  })
